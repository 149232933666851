import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import * as styles from "./Pricing2.module.scss";
import AdvancedCalculator from "./AdvancedCalculator/AdvancedCalculator";

const DefaultText = () => {
  return (
    <div className={styles.head}>
      <h2 className='title'>Fair Pricing</h2>
      <p>
        Prices are based on the number of users, not the number of features, so
        whether a team of 2 or 100 you get the same great product:
      </p>
      <p>
        Unlimited T Cards, Unlimited Card Designs, 5 Custom Boards, 1gb
        Attachment Storage and much more.
      </p>
    </div>
  );
};

const Pricing2 = ({ additional, showAdvanced, text }) => {
  const [cost, setCost] = useState(18);
  const [userVal, setUserVal] = useState(2);

  function onUserChange(e) {
    let unitCost = [10.0, 8.0, 7.0, 6.0, 5.0];
    let users = e.target.value ? parseInt(e.target.value, 10) : "";
    setUserVal(users);
    let total = 0;

    for (let i = 1; i < users + 1; i++) {
      if (i === 1) {
        total += unitCost[0];
        continue;
      }

      if (i >= 2 && i <= 10) {
        total += unitCost[1];
        continue;
      }

      if (i >= 11 && i <= 15) {
        total += unitCost[2];
        continue;
      }

      if (i >= 16 && i <= 20) {
        total += unitCost[3];
        continue;
      }

      if (i >= 21) {
        total += unitCost[4];
        continue;
      }
    }

    setCost(total);
  }

  return (
    <section className={styles.root}>
      <Container>
        {text ? <div className={styles.head}>{text}</div> : <DefaultText />}
        <Row>
          <Col xs={{ order: 1 }} lg={{ span: 6, order: 0 }}>
            <Row>
              <Col xs={6} md={4} className={styles.priceColIn}>
                <Card className={styles.card}>
                  <Card.Body className={styles.cardBody}>
                    <Card.Title
                      className={
                        styles.cardTitle +
                        " text-muted text-uppercase text-center"
                      }
                    >
                      User 1
                    </Card.Title>
                    <h6 className={styles.cardPrice}>
                      £10<div className={styles.period}>per month</div>
                    </h6>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} md={4} className={styles.priceColIn}>
                <Card className={styles.card}>
                  <Card.Body className={styles.cardBody}>
                    <Card.Title
                      className={
                        styles.cardTitle +
                        " text-muted text-uppercase text-center"
                      }
                    >
                      Users 2 - 10
                    </Card.Title>
                    <h6 className={styles.cardPrice}>
                      £8<div className={styles.period}>per user per month</div>
                    </h6>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} md={4} className={styles.priceColIn}>
                <Card className={styles.card}>
                  <Card.Body className={styles.cardBody}>
                    <Card.Title
                      className={
                        styles.cardTitle +
                        " text-muted text-uppercase text-center"
                      }
                    >
                      Users 11 - 15
                    </Card.Title>
                    <h6 className={styles.cardPrice}>
                      £7<div className={styles.period}>per user per month</div>
                    </h6>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} md={6} className={styles.priceColIn}>
                <Card className={styles.card}>
                  <Card.Body className={styles.cardBody}>
                    <Card.Title
                      className={
                        styles.cardTitle +
                        " text-muted text-uppercase text-center"
                      }
                    >
                      Users 15 - 20
                    </Card.Title>
                    <h6 className={styles.cardPrice}>
                      £6<div className={styles.period}>per user per month</div>
                    </h6>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} className={styles.priceColIn}>
                <Card className={styles.card}>
                  <Card.Body className={styles.cardBody}>
                    <Card.Title
                      className={
                        styles.cardTitle +
                        " text-muted text-uppercase text-center"
                      }
                    >
                      Users 21+
                    </Card.Title>
                    <h6 className={styles.cardPrice}>
                      £5
                      <div className={styles.period}>per user per month</div>
                    </h6>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col lg={6} className={styles.calcCol}>
            <Card className={styles.card}>
              <Card.Body className={styles.cardBody}>
                <div className={styles.calc}>
                  <Card.Title
                    className={
                      styles.cardTitle +
                      " text-muted text-uppercase text-center"
                    }
                  >
                    Quick total cost calculator
                  </Card.Title>

                  <div className={styles.calcText + " text-center"}>
                    <span className={styles.inputHolder}>
                      <input
                        className={styles.input}
                        aria-label='Number of users'
                        onChange={onUserChange}
                        type='number'
                        value={userVal}
                        min='1'
                      />
                      <FontAwesomeIcon
                        className={styles.inputEdit}
                        icon={["far", "pencil"]}
                      />
                      user{userVal > 1 ? "s" : ""}
                    </span>

                    <h6 className={styles.totalPrice}>£{cost}</h6>
                    <span className={styles.period + " d-block"}>
                      per month
                    </span>
                  </div>
                  <div className={styles.btnGroup}>
                    <Button
                      variant='primary'
                      size='lg'
                      href='https://app.tcardsonline.co.uk/register'
                    >
                      Sign up for a free trial now
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {showAdvanced && <AdvancedCalculator />}
      </Container>
    </section>
  );
};

export default Pricing2;
