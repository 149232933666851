import { OutboundLink } from "gatsby-plugin-google-gtag";
import React, { useState } from "react";
import {
  Button,
  CloseButton,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import * as styles from "./AdvancedCalculator.module.scss";

const AdvancedCalculator = () => {
  const [cost, setCost] = useState(18);
  const [userCost, setUserCost] = useState(18);
  const [boardCost, setBoardCost] = useState(0);
  const [storageCost, setStorageCost] = useState(0);
  const [userVal, setUserVal] = useState(2);
  const [boardVal, setBoardVal] = useState(5);
  const [roundedBoardVal, setRoundedBoardVal] = useState(5);
  const [storageVal, setStorageVal] = useState(1);
  const [show, setShow] = useState(false);

  function handleShow() {
    if (show) {
      document.body.classList.remove("overflow-hidden");
    } else {
      document.body.classList.add("overflow-hidden");
    }

    setShow(!show);
  }

  function onUserChange(e) {
    let unitCost = [10.0, 8.0, 7.0, 6.0, 5.0];
    let users = e.target.value ? parseInt(e.target.value, 10) : "";
    setUserVal(users);
    let total = 0;

    for (let i = 1; i < users + 1; i++) {
      if (i === 1) {
        total += unitCost[0];
        continue;
      }

      if (i >= 2 && i <= 10) {
        total += unitCost[1];
        continue;
      }

      if (i >= 11 && i <= 15) {
        total += unitCost[2];
        continue;
      }

      if (i >= 16 && i <= 20) {
        total += unitCost[3];
        continue;
      }

      if (i >= 21) {
        total += unitCost[4];
        continue;
      }
    }
    setUserCost(total);
    setCost(total + boardCost + storageCost);
  }

  function onBoardChange(e) {
    let boards = e.target.value ? parseInt(e.target.value, 10) : "";
    setBoardVal(boards);

    if (boards === "") return;

    let x = boards <= 5 ? 0 : (boards - 5) * 2;
    setRoundedBoardVal(Math.ceil(boards / 5) * 5);
    setBoardCost(x);
    setCost(userCost + x + storageCost);
  }

  function onStorageChange(e) {
    let storage = e.target.value ? parseInt(e.target.value, 10) : "";
    setStorageVal(storage);
    let x = storage <= 1 ? 0 : storage - 1;

    setStorageCost(x);
    setCost(userCost + boardCost + x);
  }

  function getBoardOptions() {
    let x = [];
    for (let i = 1; i < 21; i++) {
      let bundle = i * 5;
      x.push(
        <option key={`board-${i}`} value={bundle}>
          {bundle}
        </option>
      );
    }
    return x;
  }

  function getStorageOptions() {
    let x = [];
    for (let i = 1; i < 31; i++) {
      x.push(
        <option key={`storage-${i}`} value={i}>
          {i}
        </option>
      );
    }
    return x;
  }

  const users = (nmbr) => {
    let x = (
      <div className={styles.breakdown}>
        <div className={styles.subInfo}>1 user @ £10</div>
      </div>
    );

    function checkS(calc) {
      return calc === 1 ? "" : "s";
    }

    if (nmbr === 1) {
      x = (
        <div className={styles.breakdown}>
          <div className={styles.subInfo}>1 user @ £10</div>
        </div>
      );
    } else if (nmbr > 1 && nmbr <= 10) {
      x = (
        <div className={styles.breakdown}>
          <div className={styles.subInfo}>1 user @ £10</div>
          <div className={styles.subInfo}>
            {nmbr - 1} user{checkS(nmbr - 1)} @ £8
          </div>
        </div>
      );
    } else if (nmbr > 10 && nmbr <= 15) {
      x = (
        <div className={styles.breakdown}>
          <div className={styles.subInfo}>1 user @ £10</div>
          <div className={styles.subInfo}>9 users @ £8</div>
          <div className={styles.subInfo}>
            {nmbr - 10} user{checkS(nmbr - 10)} @ £7
          </div>
        </div>
      );
    } else if (nmbr > 15 && nmbr <= 20) {
      x = (
        <div className={styles.breakdown}>
          <div className={styles.subInfo}>1 user @ £10</div>
          <div className={styles.subInfo}>9 users @ £8</div>
          <div className={styles.subInfo}>5 users @ £7</div>
          <div className={styles.subInfo}>
            {nmbr - 15} user{checkS(nmbr - 15)} @ £6
          </div>
        </div>
      );
    } else if (nmbr > 20) {
      x = (
        <div className={styles.breakdown}>
          <div className={styles.subInfo}>1 user @ £10</div>
          <div className={styles.subInfo}>9 users @ £8</div>
          <div className={styles.subInfo}>5 users @ £7</div>
          <div className={styles.subInfo}>5 users @ £6</div>
          <div className={styles.subInfo}>
            {nmbr - 20} user{checkS(nmbr - 20)} @ £5
          </div>
        </div>
      );
    }

    return x;
  };

  return (
    <div>
      <div className={styles.text}>
        <p>
          5 Boards or 1gb storage not enough? Use our{" "}
          <button onClick={handleShow}>Advanced Cost Calculator</button> to see
          how little a subscription could cost you.
        </p>
      </div>
      {show && (
        <div className={styles.calcOuter}>
          <div className={styles.calc}>
            <div>
              <Container>
                <Row className={styles.row}>
                  <Col md={6}>
                    <div className={styles.container}>
                      <h2 className={styles.title}>Total cost calculator</h2>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>
                          How many user accounts will you need?
                        </Form.Label>
                        <Form.Control
                          type='number'
                          value={userVal}
                          onChange={onUserChange}
                          aria-label='Number of users'
                          min='1'
                        />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>How many Boards will you need?</Form.Label>
                        <Form.Control
                          type='number'
                          aria-label='Number of boards'
                          onChange={onBoardChange}
                          value={boardVal}
                          min='5'
                          step='5'
                        ></Form.Control>
                      </Form.Group>
                      {/* <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>How many Boards will you need?</Form.Label>
                        <Form.Control
                          as='select'
                          aria-label='Number of boards'
                          onChange={onBoardChange}
                          value={boardVal}
                        >
                          {getBoardOptions()}
                        </Form.Control>
                      </Form.Group> */}
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>
                          How many gigabytes of storage will you need for T Card
                          attachments?
                        </Form.Label>
                        <Form.Control
                          type='number'
                          aria-label='Storage amount'
                          value={storageVal}
                          onChange={onStorageChange}
                          min='1'
                        ></Form.Control>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className={styles.container}>
                      {/* <h2 className={styles.title}>Plan summary</h2> */}
                      <div className={styles.total}>
                        <div className={styles.price}>
                          <span>£</span>
                          {cost}
                        </div>
                        <div className={styles.pp}>
                          <div>total</div>
                          <div>
                            per month{" "}
                            <span className={styles.vat}>
                              (£
                              {(cost * 1.2 + Number.EPSILON).toFixed(2)} inc.
                              VAT)
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.includes}>
                        <h3 className={styles.includesTitle}>
                          {userVal} user account{userVal > 1 ? "s" : ""}
                        </h3>
                        {users(userVal)}
                      </div>
                      <div className={styles.includes}>
                        <h3 className={styles.includesTitle}>
                          {roundedBoardVal} board
                          {roundedBoardVal > 1 ? "s" : ""}
                        </h3>
                        {roundedBoardVal > 5 && (
                          <div className={styles.breakdown}>
                            <div className={styles.subInfo}>5 @ £0</div>
                            <div className={styles.subInfo}>
                              {roundedBoardVal - 5} @ £10 per 5 Boards
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={styles.includes}>
                        <h3 className={styles.includesTitle}>
                          {storageVal < 1 ? 1 : storageVal}gb card attachment
                          storage
                        </h3>
                        {storageVal > 1 && (
                          <div className={styles.breakdown}>
                            <div className={styles.subInfo}>1gb @ £0</div>
                            <div className={styles.subInfo}>
                              {storageVal - 1}gb @ £1 per gb
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={styles.includes}>
                        <h3 className={styles.includesTitle}>plus...</h3>
                        <div className={styles.subInfo}>Unlimited T Cards</div>
                        <div className={styles.subInfo}>
                          Unlimited Card Designs
                        </div>
                        <div className={styles.subInfo}>
                          Data Import & Export
                        </div>
                        <div className={styles.subInfo}>Mobile App</div>
                        <div className={styles.subInfo}>API Access</div>
                        <div className={styles.subInfo}>Audit Logs</div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className='text-center'>
                    <Button
                      as={OutboundLink}
                      href='https://app.tcardsonline.co.uk/register'
                      variant='primary'
                      size='lg'
                    >
                      Sign up for your FREE TRIAL now
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
            <CloseButton className={styles.close} onClick={handleShow} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvancedCalculator;
