// extracted by mini-css-extract-plugin
export var root = "Pricing2-module--root--2pppc";
export var calcCol = "Pricing2-module--calcCol--u8H6G";
export var head = "Pricing2-module--head--14rfn";
export var inputHolder = "Pricing2-module--inputHolder--3TGZk";
export var inputHolderSmall = "Pricing2-module--inputHolderSmall--2t-3q";
export var inputEdit = "Pricing2-module--inputEdit--GFw31";
export var input = "Pricing2-module--input--gDKAH";
export var inputSmall = "Pricing2-module--inputSmall--2keRs";
export var btnGroup = "Pricing2-module--btnGroup--37xLD";
export var calc = "Pricing2-module--calc--2gSJa";
export var calcText = "Pricing2-module--calcText--1DWhl";
export var priceColIn = "Pricing2-module--priceColIn--WL0UZ";
export var card = "Pricing2-module--card--1OP1O";
export var hr = "Pricing2-module--hr--2sVxj";
export var cardTitle = "Pricing2-module--cardTitle--11R-Y";
export var cardPrice = "Pricing2-module--cardPrice--17nWq";
export var totalPrice = "Pricing2-module--totalPrice--FHsQH";
export var cardBody = "Pricing2-module--cardBody--2QrVH";
export var period = "Pricing2-module--period--7aOVT";
export var pricing = "Pricing2-module--pricing--1YSob";
export var textMuted = "Pricing2-module--text-muted--30s2t";
export var btn = "Pricing2-module--btn--UHiVd";