import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as styles from "./Features.module.scss";

const Features = () => {
  return (
    <section className={styles.root}>
      <Container fluid>
        <h2 className='title italic'>
          ...and <em>much</em> more
        </h2>
        <Row>
          <Col sm={6} md={3} className={styles.col}>
            <div className={styles.box}>Unlimited T Cards</div>
          </Col>
          <Col sm={6} md={3} className={styles.col}>
            <div className={styles.box}>5 Custom Boards</div>
          </Col>
          <Col sm={6} md={3} className={styles.col}>
            <div className={styles.box}>Unlimited T Card Designs</div>
          </Col>
          <Col sm={6} md={3} className={styles.col}>
            <div className={styles.box}>Free Mobile App</div>
          </Col>
          <Col sm={6} md={3} className={styles.col}>
            <div className={styles.box}>1GB Storage for File Attachments</div>
          </Col>
          <Col sm={6} md={3} className={styles.col}>
            <div className={styles.box}>200 Monthly API Credits</div>
          </Col>
          <Col sm={6} md={3} className={styles.col}>
            <div className={styles.box}>Data Import and Export</div>
          </Col>
          <Col sm={6} md={3} className={styles.col}>
            <div className={styles.box}>Accredited Secure Environment</div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features;
