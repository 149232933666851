// extracted by mini-css-extract-plugin
export var calcOuter = "AdvancedCalculator-module--calcOuter--1ZWfd";
export var calc = "AdvancedCalculator-module--calc--1s3Ms";
export var row = "AdvancedCalculator-module--row--36xb0";
export var text = "AdvancedCalculator-module--text--8ZVNA";
export var container = "AdvancedCalculator-module--container--3XNQ8";
export var title = "AdvancedCalculator-module--title--lSQDV";
export var total = "AdvancedCalculator-module--total--cx7jN";
export var price = "AdvancedCalculator-module--price--2WACa";
export var pp = "AdvancedCalculator-module--pp--4C-fc";
export var vat = "AdvancedCalculator-module--vat--1EAHl";
export var breakdown = "AdvancedCalculator-module--breakdown--Y1reH";
export var subInfo = "AdvancedCalculator-module--subInfo--3RQ1v";
export var includes = "AdvancedCalculator-module--includes--X4neA";
export var includesTitle = "AdvancedCalculator-module--includesTitle--sIeqU";
export var close = "AdvancedCalculator-module--close--aHT4Q";